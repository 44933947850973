import { Link } from 'gatsby'
import { QuestionMarkIcon } from 'src/components/Icons/QuestionMarkIcon'
import Logo from 'src/components/ui/Logo'
import { useMobile } from 'src/hooks/useMobile'

export function FooterCheckout() {
  const { isMobile } = useMobile()

  return (
    <footer className="w-full font-inter flex flex-col items-center py-10 border-t border-t-neutral04 sm:flex-row sm:justify-between sm:px-20">
      <Logo width={isMobile ? 130 : 186} bgFill="#3643BA" />
      <div className="flex flex-row gap-6 items-center order-1 sm:order-none">
        {!isMobile && (
          <div className="flex flex-col gap-0.5">
            <span className="text-sm text-black font-semibold">
              Transação segura
            </span>
            <span className="text-xs text-tertiary">Contra fraudes</span>
          </div>
        )}

        <img
          alt="Ícone Lets Encrypt"
          aria-label="Ícone de segurança Lets Encrypt"
          src="https://decathlonstore.vtexassets.com/arquivos/lets-encrypt.jpg"
        />

        <img
          alt="Ícone PCI"
          aria-label="Ícone de segurança PCI"
          src="https://decathlonstore.vtexassets.com/arquivos/pci-certified.jpg"
        />

        <img
          alt="Ícone Konduto"
          aria-label="Ícone de segurança Konduto"
          src="https://decathlonstore.vtexassets.com/arquivos/konduto.jpg"
        />
      </div>
      <div className="flex flex-col items-center gap-1 mt-4 mb-10 sm:items-start sm:mt-0 sm:mb-0 sm:gap-2 sm:flex-row">
        <QuestionMarkIcon />
        <div className="flex flex-col text-sm items-center gap-1 sm:gap-0 sm:items-start">
          <span className="text-restructure-primary">
            Ficou com alguma dúvida?
          </span>
          <Link to="/atendimento" className="text-tertiary underline">
            Fale conosco
          </Link>
        </div>
      </div>
    </footer>
  )
}
