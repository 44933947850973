import { useCallback } from 'react'

import type { LinxMetaCart } from '../types'
import { useLinxEventDispatch } from './useLinxEventDispatch'

type CartViewProps = {
  items: Array<{ pid: string; sku: string; price: number; quantity: number }>
  id: string
}

export const useLinxCartView = () => {
  const { dispatchLinxEvent } = useLinxEventDispatch()

  const sendCartViewEvent = useCallback(
    ({ items, id }: CartViewProps) => {
      const linxMetaCart: LinxMetaCart = {
        page: 'cart',
        salesChannel: '3',
        id,
        items,
      }

      return dispatchLinxEvent(linxMetaCart)
    },
    [dispatchLinxEvent]
  )

  return { sendCartViewEvent }
}
