import type { IconProps } from './typesIcons'

export function MinusIcon({
  width = 24,
  height = 24,
  color = '#3643BA',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.54169 10C3.54169 9.65482 3.82151 9.375 4.16669 9.375H15.8334C16.1785 9.375 16.4584 9.65482 16.4584 10C16.4584 10.3452 16.1785 10.625 15.8334 10.625H4.16669C3.82151 10.625 3.54169 10.3452 3.54169 10Z"
        fill={color}
      />
    </svg>
  )
}
