import type { IconProps } from './typesIcons'

export const LockIcon = ({
  height = 16,
  width = 16,
  color = '#3E4751',
  className,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M12.6667 7.33203H3.33333C2.59695 7.33203 2 7.92898 2 8.66536V13.332C2 14.0684 2.59695 14.6654 3.33333 14.6654H12.6667C13.403 14.6654 14 14.0684 14 13.332V8.66536C14 7.92898 13.403 7.33203 12.6667 7.33203Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.66797 7.33203V4.66536C4.66797 3.78131 5.01916 2.93346 5.64428 2.30834C6.2694 1.68322 7.11725 1.33203 8.0013 1.33203C8.88536 1.33203 9.7332 1.68322 10.3583 2.30834C10.9834 2.93346 11.3346 3.78131 11.3346 4.66536V7.33203"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
