import type { Item } from 'src/sdk/checkout/data/types'

import type { Product as ProductEvent } from './types/common/product'

type MakeCartProduct = {
  item: Item
  triggerEvent?: string
}

export const makeCartProduct = ({
  item,
  triggerEvent = 'carrinho',
}: MakeCartProduct): ProductEvent => {
  const parsedPrice = item?.price
    .replaceAll('R$', '')
    .replaceAll(' ', '')
    .replaceAll(',', '.')

  return {
    brand: item?.brand,
    category: item?.categoryName,
    dimension1: item?.productRefId, // super modelo sap
    dimension2: item?.refId, // id sku sap
    dimension12: item?.seller,
    dimension13: item?.sellerName,
    dimension21: item?.category?.replaceAll('/', ' > '),
    dimension22: item?.id, // id sku vtex
    dimension23: item?.ean, // id modelo sap (no app é item category 5)
    dimension24: triggerEvent, // origem do evento
    id: item?.productId, // id modelo vtex
    name: item?.productName,
    price: parsedPrice,
    quantity: 1,
    variant: `${item?.id} | ${item?.skuName}`,
  }
}
