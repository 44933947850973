export const parseStringPrice = (priceString: string) => {
  // Remove the currency symbol and any non-numeric characters except the comma
  const cleanedString = priceString.replace(/[^\d,]/g, '')

  // Replace the comma with a period
  const normalizedString = cleanedString.replace(',', '.')

  // Convert the string to a number
  return parseFloat(normalizedString)
}
