export const QuestionMarkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
    >
      <path
        d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.58984 9.00057C9.82495 8.33224 10.289 7.76868 10.8998 7.4097C11.5106 7.05073 12.2287 6.91951 12.927 7.03928C13.6253 7.15906 14.2587 7.52209 14.7149 8.0641C15.1712 8.6061 15.4209 9.29209 15.4198 10.0006C15.4198 12.0006 12.4198 13.0006 12.4198 13.0006"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 17H12.51"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
