export const SummaryProductsSkeleton = () => {
  return (
    <div className="flex flex-col gap-[64px] sm:gap-6">
      {Array.from({ length: 3 }).map((_, index) => (
        <div
          key={index}
          className="animate-pulse grid grid-cols-2 grid-rows-2 gap-4 w-full sm:gap-6 sm:py-4 sm:grid-cols-[1fr_3fr_1fr]"
        >
          <div className="w-[156px] h-[156px] rounded-sm row-span-2 bg-neutral04 sm:-my-4" />
          <div className="flex flex-col gap-2">
            <div className="h-[18px] w-[160px] rounded-sm bg-neutral04 sm:h-6 sm:w-[371px]" />
            <div className="h-[18px] w-[87px] rounded-sm bg-neutral04 sm:w-52" />
          </div>
          <div className="flex gap-2 mt-3 col-span-2 row-start-3 sm:col-start-2 sm:row-start-2">
            <div className="flex-1 h-[36px] w-full rounded-[100px] border-restructure-border-secondary border sm:flex-none sm:w-[130px]" />
            <div className="h-[36px] w-[36px] rounded-[100px] border-restructure-border-secondary border" />
          </div>
          <div className="w-[97px] h-[18px] rounded-sm col-start-2 sm:col-start-3 sm:row-start-1 bg-neutral04" />
        </div>
      ))}
    </div>
  )
}
