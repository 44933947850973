import type { Item } from 'src/sdk/checkout/data/types'
import { useCheckout } from 'src/sdk/checkout/useCheckout'

import { ProductItem } from './ProductItem'
import { SummaryProductsSkeleton } from './SummaryProductsSkeleton'

type AccObj = {
  [value: string]: Item[]
}

export function SummaryProducts() {
  const { isLoading, orderForm } = useCheckout()

  const isPageLoading = !orderForm?.orderFormId && isLoading

  const itemsSortedBySeller = orderForm?.items.reduce((acc: AccObj, item) => {
    if (!acc[item.sellerName]) {
      acc[item.sellerName] = []
    }

    acc[item.sellerName].push(item)

    return acc
  }, {})

  if (isPageLoading) {
    return <SummaryProductsSkeleton />
  }

  return (
    <section className="flex-1 font-inter">
      {Object.keys(itemsSortedBySeller).map((seller, idxSeller) => (
        <div key={idxSeller} className="mb-10">
          <span className="text-sm text-tertiary font-bold">
            Vendido e entregue por {seller}
          </span>
          <div className="flex flex-col gap-[64px] mt-6 sm:gap-6" key={seller}>
            {itemsSortedBySeller[seller].map((item, idx) => (
              <ProductItem key={`${item.id}-${idx}`} item={item} />
            ))}
          </div>
        </div>
      ))}
    </section>
  )
}
