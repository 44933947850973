import type { IconProps } from './typesIcons'

export function TrashIcon({
  width = 16,
  height = 16,
  color = '#101010',
}: IconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 16 16"
      fill={color}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.5 4C1.5 3.72386 1.72386 3.5 2 3.5H14C14.2761 3.5 14.5 3.72386 14.5 4C14.5 4.27614 14.2761 4.5 14 4.5H2C1.72386 4.5 1.5 4.27614 1.5 4Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66665 1.83331C6.44563 1.83331 6.23367 1.92111 6.07739 2.07739C5.92111 2.23367 5.83331 2.44563 5.83331 2.66665V3.99998C5.83331 4.27612 5.60946 4.49998 5.33331 4.49998C5.05717 4.49998 4.83331 4.27612 4.83331 3.99998V2.66665C4.83331 2.18042 5.02647 1.7141 5.37028 1.37028C5.7141 1.02647 6.18042 0.833313 6.66665 0.833313H9.33331C9.81954 0.833313 10.2859 1.02647 10.6297 1.37028C10.9735 1.7141 11.1666 2.18042 11.1666 2.66665V3.99998C11.1666 4.27612 10.9428 4.49998 10.6666 4.49998C10.3905 4.49998 10.1666 4.27612 10.1666 3.99998V2.66665C10.1666 2.44563 10.0788 2.23367 9.92257 2.07739C9.76629 1.92111 9.55433 1.83331 9.33331 1.83331H6.66665ZM3.33331 3.49998C3.60946 3.49998 3.83331 3.72384 3.83331 3.99998V13.3333C3.83331 13.5543 3.92111 13.7663 4.07739 13.9226C4.23367 14.0788 4.44563 14.1666 4.66665 14.1666H11.3333C11.5543 14.1666 11.7663 14.0788 11.9226 13.9226C12.0788 13.7663 12.1666 13.5543 12.1666 13.3333V3.99998C12.1666 3.72384 12.3905 3.49998 12.6666 3.49998C12.9428 3.49998 13.1666 3.72384 13.1666 3.99998V13.3333C13.1666 13.8195 12.9735 14.2859 12.6297 14.6297C12.2859 14.9735 11.8195 15.1666 11.3333 15.1666H4.66665C4.18042 15.1666 3.7141 14.9735 3.37028 14.6297C3.02647 14.2859 2.83331 13.8195 2.83331 13.3333V3.99998C2.83331 3.72384 3.05717 3.49998 3.33331 3.49998Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66669 6.83331C6.94283 6.83331 7.16669 7.05717 7.16669 7.33331V11.3333C7.16669 11.6095 6.94283 11.8333 6.66669 11.8333C6.39054 11.8333 6.16669 11.6095 6.16669 11.3333V7.33331C6.16669 7.05717 6.39054 6.83331 6.66669 6.83331Z"
        fill={color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33331 6.83331C9.60946 6.83331 9.83331 7.05717 9.83331 7.33331V11.3333C9.83331 11.6095 9.60946 11.8333 9.33331 11.8333C9.05717 11.8333 8.83331 11.6095 8.83331 11.3333V7.33331C8.83331 7.05717 9.05717 6.83331 9.33331 6.83331Z"
        fill={color}
      />
    </svg>
  )
}
