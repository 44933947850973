import { useCheckout } from 'src/sdk/checkout/useCheckout'

const steps = [
  { name: 'Carrinho', isActive: true },
  {
    name: 'Identificação',
    isActive: false,
  },
  {
    name: 'Entrega',
    isActive: false,
  },
  {
    name: 'Pagamento',
    isActive: false,
  },
]

export function StepsCheckout() {
  const { isEmpty } = useCheckout()

  if (isEmpty) {
    return <></>
  }

  return (
    <div className="w-full">
      <ul className="font-inter pb-6 mt-4 mx-10 flex relative gap-2 justify-center items-baseline sm:mt-10 sm:items-center sm:gap-4">
        {steps.map((step, index) => (
          <>
            <div
              className="flex flex-col gap-2 items-center sm:flex-row"
              key={index}
            >
              <span
                className={`flex justify-center items-center w-6 h-6 rounded-xl desktop-caption-semibold text-restructure-primary-inverted ${
                  step.isActive
                    ? 'bg-restructure-background-action-1'
                    : 'bg-restructure-icon-disabled'
                }`}
              >
                {index + 1}
              </span>
              <span
                className={`text-xs absolute bottom-0 font-semibold sm:relative sm:text-sm ${
                  step.isActive
                    ? 'text-restructure-action'
                    : 'text-restructure-disabled'
                }`}
              >
                {step.name}
              </span>
            </div>
            {index !== steps.length - 1 && (
              <span className="block w-[55px] border-t-[1px] border-gray sm:w-[6%] sm:max-w-[110px] sm:m-0" />
            )}
          </>
        ))}
      </ul>
    </div>
  )
}
