import { BagCartOutline } from 'src/components/Icons/BagCartOutline'
import { ExchangeIcon } from 'src/components/Icons/ExchangeIcon'
import { UmbrellaIcon } from 'src/components/Icons/UmbrellaIcon'

const BenefitsCart = () => {
  return (
    <div>
      <ul className="flex flex-col gap-4 font-semibold text-sm font-inter">
        <li className="flex items-center gap-2">
          <ExchangeIcon /> Troca facilitada para produtos Decathlon
        </li>
        <li className="flex items-center gap-2">
          <UmbrellaIcon /> Itens Decathlon garantia mínima de 2 anos
        </li>
        <li className="flex items-center gap-2">
          <BagCartOutline width={21} height={21} /> Compre e retire grátis na
          loja no mesmo dia
        </li>
      </ul>
    </div>
  )
}

export default BenefitsCart
