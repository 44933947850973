import type { IconProps } from './typesIcons'

export function ErrorIcon({
  color = '#E3262F',
  height = 14,
  width = 14,
  className,
}: IconProps) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.333252 7C0.333252 10.682 3.31792 13.6667 6.99992 13.6667C10.6819 13.6667 13.6666 10.682 13.6666 7C13.6666 3.318 10.6819 0.333328 6.99992 0.333328C3.31792 0.333328 0.333252 3.318 0.333252 7ZM12.3333 6.99999C12.3333 9.94551 9.94544 12.3333 6.99992 12.3333C4.0544 12.3333 1.66659 9.94551 1.66659 6.99999C1.66659 4.05448 4.0544 1.66666 6.99992 1.66666C9.94544 1.66666 12.3333 4.05448 12.3333 6.99999ZM7.66659 9V10.3333H6.33325V9H7.66659ZM7.66659 7.66666V3.66666H6.33325V7.66666H7.66659Z"
        fill={color}
      />
    </svg>
  )
}
