import { Link } from 'gatsby'
import { ArrowLeft } from 'src/components/Icons/ArrowLeft'
import { LockIcon } from 'src/components/Icons/LockIcon'
import Logo from 'src/components/ui/Logo'
import { useMobile } from 'src/hooks/useMobile'

export function HeaderCheckout() {
  const { isMobile } = useMobile()

  return (
    <header className="w-full">
      <div className="flex justify-between py-7 px-5 sm:py-5 sm:px-[80px]">
        {!isMobile && (
          <Link
            to="/"
            className="text-restructure-action font-semibold flex items-center gap-3 text-sm"
          >
            <ArrowLeft width={20} color="#3643BA" className="mb-0.5" />
            Voltar ao site
          </Link>
        )}

        <Link to="/">
          <Logo width={isMobile ? 130 : 186} bgFill="#3643BA" />
        </Link>

        <span className="flex flex-row-reverse items-center gap-2 text-xs sm:flex-row">
          <LockIcon className="mb-1" />
          Ambiente seguro
        </span>
      </div>
    </header>
  )
}
