import { useCheckout } from 'src/sdk/checkout/useCheckout'

import TotalizersCartSkeleton from './TotalizersCartSkeleton'

const TotalizersCart = () => {
  const { isLoading, orderForm } = useCheckout()
  const { totalizers } = orderForm

  const itemsQtty = orderForm.items.reduce((acc, cur) => {
    acc += cur.quantity

    return acc
  }, 0)

  if (isLoading) {
    return <TotalizersCartSkeleton />
  }

  return (
    <div>
      <div className="flex flex-col gap-2 mt-6 font-inter text-base sm:text-sm font-normal">
        <div className="flex justify-between">
          <span>Itens ({itemsQtty})</span>
          <span>{totalizers.items}</span>
        </div>

        {totalizers.discounts && (
          <div className="flex justify-between">
            <span>Descontos</span>
            <span>{totalizers.discounts}</span>
          </div>
        )}

        {totalizers.shipping && (
          <div className="flex justify-between">
            <span>Entrega</span>
            <span>
              {totalizers.shipping === 'R$ 0,00'
                ? 'Grátis'
                : totalizers.shipping}
            </span>
          </div>
        )}
      </div>

      <div className="h-[1px] w-full bg-restructure-background-neutral-4 my-4" />

      <div className="flex justify-between items-baseline mt-6 sm:mt-0 mb-12 sm:mb-0">
        <span className="text-base text-[#101010]">Total</span>
        <div className="flex gap-2 items-center justify-end">
          <span className="text-2xl font-medium text-[#15181B]">
            {totalizers.total}
          </span>
          <span className="text-sm leading-[21px] text-[#383838]">à vista</span>
        </div>
      </div>
    </div>
  )
}

export default TotalizersCart
