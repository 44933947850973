import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}
export function UmbrellaIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M15 16.3333C15 16.9964 14.7366 17.6322 14.2678 18.1011C13.7989 18.5699 13.1631 18.8333 12.5 18.8333C11.837 18.8333 11.2011 18.5699 10.7322 18.1011C10.2634 17.6322 10 16.9964 10 16.3333V10.5M19.1667 10.5C18.949 8.21963 17.8892 6.10228 16.1942 4.56137C14.4992 3.02047 12.2907 2.16666 10 2.16666C7.70929 2.16666 5.50081 3.02047 3.80581 4.56137C2.11082 6.10228 1.05101 8.21963 0.833344 10.5H19.1667Z"
        stroke="#101010"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
