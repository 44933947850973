import Skeleton from 'react-loading-skeleton'

export default function TotalizersCartSkeleton() {
  return (
    <div className="mt-6 flex flex-col gap-y-2">
      {[1, 2, 3].map((item) => {
        return (
          <div className="flex justify-between" key={item}>
            <Skeleton width="80px" height="18px" baseColor="#D9DDE1" />
            <Skeleton width="80px" height="18px" baseColor="#D9DDE1" />
          </div>
        )
      })}

      <div className="h-[1px] w-full bg-restructure-background-neutral-4 my-1" />

      <div className="flex justify-between mb-8 sm:mb-0">
        <Skeleton width="80px" height="18px" baseColor="#D9DDE1" />
        <Skeleton width="80px" height="32px" baseColor="#D9DDE1" />
      </div>
    </div>
  )
}
