import { formatterPrice } from 'src/utils/formatterPrice'

interface ProductPriceProps {
  additionalInformation: {
    sellerOffer: {
      cashPrice: number
      price: number
      cashDiscountPercentage: number
      listPrice: number
      hasDiscount: boolean
    }
  }
}

export const ProductPrice = ({ additionalInformation }: ProductPriceProps) => {
  const {
    sellerOffer: {
      price,
      listPrice,
      hasDiscount,
      cashPrice,
      cashDiscountPercentage,
    },
  } = additionalInformation

  const hasCashPrice = cashDiscountPercentage > 0

  return (
    <div className="flex flex-col pt-4 col-start-2 min-w-max gap-1 sm:pt-0 sm:items-end sm:col-start-3 sm:row-start-1">
      {hasDiscount && (
        <span className="text-sm text-[#687787] line-through">
          {formatterPrice(listPrice)}
        </span>
      )}
      {hasCashPrice ? (
        <>
          <span className="text-sm leading-[21px] text-[#383838]">
            <strong className="text-[#101010] text-xl leading-[20px] font-medium">
              {formatterPrice(cashPrice)}
            </strong>{' '}
            à vista
          </span>
          <span className="text-[#383838] text-sm leading-[21px]">
            ou{' '}
            <strong className="font-semibold">{formatterPrice(price)}</strong>{' '}
            no cartão
          </span>
        </>
      ) : (
        <span className="font-semibold desktop-price-card text-restructure-primary">
          {formatterPrice(price)}
        </span>
      )}
    </div>
  )
}
