import type { Item } from 'src/sdk/checkout/data/types'

import { makeCartProduct } from './makeCartProduct'
import type { RemoveFromCartEvent } from './types/removeToCartEvent'

export const makeCartRemoveEvent = (
  items: Array<{ item: Item }>
): RemoveFromCartEvent => {
  return {
    event: 'removeFromCart',
    ecommerce: {
      currencyCode: 'BRL',
      remove: {
        products: items?.map((item) => makeCartProduct(item)),
      },
    },
  }
}
