import type { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> {
  color?: string
}
export function ExchangeIcon(props: Props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      {...props}
    >
      <path
        d="M2 6L19 6"
        stroke="#101010"
        strokeWidth="1.59552"
        strokeLinejoin="round"
      />
      <path
        d="M15 2L19 6L15 10"
        stroke="#101010"
        strokeWidth="1.59552"
        strokeLinejoin="round"
      />
      <path
        d="M19 15L2 15"
        stroke="#101010"
        strokeWidth="1.59552"
        strokeLinejoin="round"
      />
      <path
        d="M6 19L2 15.5L6 12"
        stroke="#101010"
        strokeWidth="1.59552"
        strokeLinejoin="round"
      />
    </svg>
  )
}
