import type { Item } from 'src/sdk/checkout/data/types'

import { makeCartProduct } from './makeCartProduct'
import type { AddToCartEvent } from './types/addToCartEvent'

export const makeCartAddEvent = (
  items: Array<{ item: Item }>
): AddToCartEvent => {
  return {
    event: 'addToCart',
    ecommerce: {
      currencyCode: 'BRL',
      add: {
        products: items?.map((item) => makeCartProduct(item)),
      },
    },
  }
}
