import { useState } from 'react'
import type { Item } from 'src/sdk/checkout/data/types'
import { makeCartAddEvent } from 'src/utils/restructure/analytics/makeCartAddEvent'
import { makeCartRemoveEvent } from 'src/utils/restructure/analytics/makeCartRemoveEvent'
import { sendEvent } from 'src/utils/restructure/analytics'
import { useProductQuantity } from 'src/components/restructure/product/sections/Sidebar/ProductAction/ProductQuantity/hooks/useProductQuantity'
import { useToastContext } from 'src/components/ui/ToastMessage/ToastContext'
import { useMobile } from 'src/hooks/useMobile'
import { useCheckout } from 'src/sdk/checkout/useCheckout'
import { MinusIcon } from 'src/components/Icons/MinusIcon'
import { LoadingIcon } from 'src/components/Icons/LoadingIcon'
import { PlusIcon } from 'src/components/Icons/PlusIcon'
import { ErrorIcon } from 'src/components/Icons/ErrorIcon'
import { TrashIcon } from 'src/components/Icons/TrashIcon'

interface ProductQuantityProps {
  item: Item
}

export const ProductQuantity = ({ item }: ProductQuantityProps) => {
  const [loadingQuantity, setLoadingQuantity] = useState({
    id: '',
    isLoading: false,
  })

  const { updateItem, orderForm } = useCheckout()
  const { sendToast } = useToastContext()
  const { maxValue, minValue } = useProductQuantity()
  const { isMobile } = useMobile()

  const { messages } = orderForm

  const { quantity, itemIndex, id, ean, availability } = item

  const quantityNotAvailableMessage = messages.find(
    (message) => message.code === 'itemQuantityNotAvailable'
  )

  const cannotIncreaseQuantity =
    quantity === maxValue || quantityNotAvailableMessage?.fields?.ean === ean

  const cannotDecreaseQuantity = quantity === minValue

  const isProductAvailable = availability === 'available'

  const handleRemoveItem = async () => {
    await handleChangingItems(0)
    sendToast({
      text: 'Item removido do carrinho',
      variant: 'success',
    })
  }

  const handleChangingItems = async (newQuantity: number) => {
    setLoadingQuantity({ id, isLoading: true })
    const itemToBeUpdated = {
      index: itemIndex,
      quantity: newQuantity,
    }

    await updateItem(itemToBeUpdated)
    setLoadingQuantity({ id, isLoading: false })
  }

  const sendCartAddEvent = (eventType: string) => {
    if (!item) {
      return
    }

    const addToCartEvent = makeCartAddEvent([{ item }])
    const removeFromCartEvent = makeCartRemoveEvent([{ item }])

    eventType === 'add'
      ? sendEvent(addToCartEvent)
      : sendEvent(removeFromCartEvent)
  }

  return (
    <>
      {isProductAvailable ? (
        <div className="flex justify-between items-center w-full h-[36px] rounded-[100px] border-gray hover:border-restructure-border-hover transition border py-[7.5px] px-4 sm:w-[130px]">
          <button
            disabled={cannotDecreaseQuantity}
            onClick={() => {
              handleChangingItems(quantity - 1)
              sendCartAddEvent('remove')
            }}
          >
            <MinusIcon
              width={20}
              height={20}
              color={cannotDecreaseQuantity ? '#949494' : '#101010'}
            />
          </button>
          <span className="text-sm text-restructure-secondary">
            {loadingQuantity.isLoading && loadingQuantity.id === id ? (
              <LoadingIcon />
            ) : (
              quantity
            )}
          </span>
          <button
            disabled={cannotIncreaseQuantity}
            onClick={() => {
              handleChangingItems(quantity + 1)
              sendCartAddEvent('add')
            }}
          >
            <PlusIcon
              width={20}
              height={20}
              color={cannotIncreaseQuantity ? '#949494' : '#101010'}
            />
          </button>
        </div>
      ) : (
        <div className="flex items-center gap-1 mr-6 mb-[7px]">
          <ErrorIcon color="#AB0009" />
          <span className="text-restructure-error mt-xxxs">
            Produto não disponível
          </span>
        </div>
      )}

      <button
        onClick={() => handleRemoveItem()}
        className={`${
          !isProductAvailable
            ? 'justify-center gap-2 w-full sm:w-auto'
            : 'justify-between'
        } p-[10px] flex items-center rounded-[100px] border-gray border hover:border-restructure-border-hover transition text-restructure-secondary`}
      >
        <TrashIcon />
        {!isProductAvailable && isMobile && 'Deletar'}
      </button>
    </>
  )
}
