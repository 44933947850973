import { createPortal } from 'react-dom'
import { useMobile } from 'src/hooks/useMobile'

import { CashbackCheckout } from '../../cashbackCheckout'
import TotalizersCart from './TotalizersCart'
import BenefitsCart from './BenefitsCart'
import GoToShipping from './GoToShipping'
import { CartCoupon } from './CartCoupon/CartCoupon'

export function SummaryCart() {
  const { isMobile } = useMobile()

  return (
    <section className="max-w-[411px] sticky top-[2%] w-full h-fit gap-1 flex flex-col mb-12">
      {!isMobile && <CashbackCheckout />}
      <CartCoupon />

      <TotalizersCart />

      {isMobile ? (
        createPortal(<GoToShipping />, document.body)
      ) : (
        <GoToShipping />
      )}

      <BenefitsCart />
    </section>
  )
}
