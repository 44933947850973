import Skeleton from 'react-loading-skeleton'

export default function GoToShippingSkeleton() {
  return (
    <div className="sm:w-full h-[64px] min-h-[64px] bg-restructure-background-action-1 my-8 rounded-round grid text-center items-center w-[calc(100%-32px)] mx-auto">
      <Skeleton
        width="60%"
        height="21px"
        baseColor="#D9DDE1"
        style={{ opacity: '.5' }}
      />
    </div>
  )
}
